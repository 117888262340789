import React from 'react'
import CenterBox from '../CenterBox'
import Button from '../Button'
import GradientText from '../GradientText'
import { IconButton } from '@mui/material'
import Image from '../Image'
// import Twitter from "../../assets/images/twitter.svg";
// import Discord from "../../assets/images/discord.svg";
import Telegram from "../../assets/images/telegram.svg";
import Medium from "../../assets/images/medium.svg";
import Website from "../../assets/images/website.svg";
import Link from "../Link"
import "./style.scss"
import Discord from '../../assets/images/DiscordButton.png'
import X from '../../assets/images/x.png'
import BuiltonXRP from '../../assets/images/Built-on-XRPL-BW-Horizontal.png'

export default function Footer() {
    return (
        <div className='footer flex space-x-[100px] justify-center py-[100px]'>
            <div className='flex flex-col space-y-[40px]'>
                <p className='title pb-[20px]'>XRP.FINANCE</p>
                <p className='content'>BUILDING THE ULTIMATE <br/> DEFI PLATFORM ON XRP</p>
                <img src={BuiltonXRP} alt='built on xrp'/>
            </div>
            <div className='flex flex-col  space-y-[40px]'>
                <p className='title'>PLATFORM</p>
                <div className='grid grid-cols-2'>
                    <div className='flex flex-col'>
                        <a className = 'content' href="/">Home</a>
                        <a className = 'content' href="/swap">Swap</a>
                        <a className = 'content' href="/liquidity">Liquidity</a>
                        {/* <a className = 'content' href="/bridge">Bridge</a> */}
                    </div>
                    <div className='flex flex-col'>
                        {/* <a className = 'content' href="/launchpad">Launchpad</a> */}
                        <a className = 'content' href="https://forms.gle/hjFGdHJn5saQqtSn7">Feedback</a>
                        <a className = 'content' href="/">Terms and Conditions</a>

                    </div>
                </div>
            </div>
            <div className='flex flex-col  space-y-[40px]'>
                <p className='title'>CONTACT</p>
                <a className='content' href='mailto:'>Email</a>
            </div>
            <div className='flex flex-col  space-y-[40px]'>
                <p className='title'>FOLLOW US</p>
                <div className='flex flex-col space-y-2'>
                    <a href='/'>
                        <div className=' flex justify-center py-1 bg-[#5865F2] w-[188px] h-[50px] rounded-lg'>
                            <img src={Discord} alt='discord'/>
                        </div>                    
                    </a>

                    <a href='/'>
                        <div className='flex justify-center  py-1 bg-black  w-[188px] h-[50px] rounded-lg'>
                            <img src={X} alt='x'/>
                        </div>               
                    </a>
                </div>
                

            </div>
        </div>
    )
}
