import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, IconButton, Container, Menu, MenuItem, Typography } from '@mui/material';
import LogoImage from "../../assets/images/logo.png";
import Image from '../../components/Image';
import ImageButton from '../ImageButton';
import { ImageButtonCross } from '../ImageButton';
import "./style.scss";
import UserIcon from '../../assets/images/user.svg';
import Hamburger from 'hamburger-react';
import XummIcon from "../../assets/images/xaman.png";
import Link from '../Link';
import { CopyAll, Logout } from '@mui/icons-material';
import CenterBox from '../CenterBox';
import { AppContext } from '../../index';
import { formatAddress } from '../Global';
import { toast } from 'react-hot-toast';
import { xumm } from '../../index';
import sdk from '@crossmarkio/sdk';
import Button from '../Button';
import BalanceModal from '../BalanceModal';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
const whitepaper_link = "https://www.dropbox.com/scl/fi/p21af6qw98n7t641gw9y5/XRPLPAD-Vision.pdf?rlkey=iajfeu3ftz6hs9o7uhvkqygd6&dl=0";

export default function Navbar() {

    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const { address, setAddress, balances, loadingBalance } = useContext(AppContext);

    const [openBal, setOpenBal] = useState(false)
    const [xrpBalance, setXrpBalance] = useState(0)

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWalletBtn = async () => {
        if (!address && !xumm.runtime.xapp) {
            try {
                const data = await xumm.authorize();
                if(data?.me?.account){
                    toast.success(`Conected to Xumm`);
                }                
            } catch (error) {
                toast.error(error.message);
            }
        } else if (address) {
            toast.success('Connected');
            setAddress(address);
        }

        handleClose();
    }

    const handleWalletCrossmark = async () => {
        if (address) {
            toast.success(`Wallet Conected`);
            handleClose();
            return;
        }
        // const sdk = window.xrpl.crossmark;
        let isConnected = await sdk.async.connect();
        if(isConnected){
            let { request, response, createdAt, resolvedAt } =   await sdk.async.signInAndWait()
            if(response.data.meta.isSuccess){
                setAddress(response.data.address);
                localStorage.setItem("crossmark", JSON.stringify(response.data));
                toast.success(`Conected to Crossmark`);
            }else{
                if(response.data.meta.isRejected){
                    toast.error(`Rejected to connect`);
                }else{
                    toast.error(`Failed to connect`);
                }
                
            }          
        }


        handleClose();
    }

    const disconnect = useCallback(() => {
        try {
            xumm.runtime.xapp = false;
            xumm.logout();
            localStorage.removeItem("crossmark");
            setAddress('');
            toast.success('Disconnected');
        } catch (error) {
            toast.error('Failed to disconnect. Please try again');
        }
    }, []);

    const copyAddress = () => {
        navigator.clipboard.writeText(address);
        toast.success('Copied');
    }

    const onHandleClose = () => {
        setOpenModal(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const isTop = window.scrollY > 100;
            const header = document.querySelector('.fixed-header');
            const balance = document.querySelector('.fixed-balance');
            if (isTop) {
                header.classList.add('scrolled-header');
                if (balance) {
                    balance.classList.remove('top-7');
                    balance.classList.add('top-3');
                }
            } else {
                header.classList.remove('scrolled-header');
                if (balance) {
                    balance.classList.remove('top-3');
                    balance.classList.add('top-7');
                }

            }
        });
    }, []);

    useEffect(() => {

        const xrpBalance = balances.find(({ currency }) => currency === 'XRP')
        if (xrpBalance) setXrpBalance(xrpBalance.balance)
    }, [balances])

    return (
        <Box className="fixed-header">
            <BalanceModal open={openModal} onClose={onHandleClose} />
            <Container className='navbar-container' disableGutters>
                <Image className="icon" href="/" src={LogoImage} />
                <CenterBox>
                    <Box className="nav-link-box">
                        <IconButton
                            id="basic-button"
                            className="menu-icon"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <Hamburger toggled={open} />
                        </IconButton >
                        <Box className="nav-link-box list-items">
                            <Link href="/" ><Typography className="nav-link" variant="h6">HOME</Typography></Link>
                            <Link href="/swap" ><Typography className="nav-link" variant="h6">TRADE</Typography></Link>
                            <Link href="/liquidity/all" ><Typography className="nav-link" variant="h6">LIQUIDITY</Typography></Link>
                            {/* <Link href="/projects" ><Typography className="nav-link" variant="h6">LAUNCHPAD</Typography></Link> */}
                            {/* <Link href="/bridge" ><Typography className="nav-link" variant="h6">BRIDGE</Typography></Link> */}
                            {/* <Link href="/faucet" ><Typography className="nav-link" variant="h6">Faucet</Typography></Link> */}
                            <Link href="/leaderboard" ><Typography className="nav-link" variant="h6">LEADERBOARD</Typography></Link>
                            <Link href="https://discord.gg/dmvTjyrjgh" ><Typography className="nav-link" variant="h6">SOCIALS</Typography></Link>
                            <Link href="https://forms.gle/ZdbyXFebgZCuH5h87"  ><Typography className="nav-link" variant="h6">CONTACT</Typography></Link>
                            {/* <Link href={whitepaper_link} ><Typography className="nav-link" variant="h6">Whitepaper</Typography></Link> */}
                            {!address && <ImageButton onClick={handleWalletBtn} />}
                            {!address && <ImageButtonCross text="Crossmark" onClick={handleWalletCrossmark} />}
                        </Box>
                    </Box>
                    <div className='w-[150px]'>

                    </div>
                    {address &&
                        // <CenterBox>
                        //     <Image src={UserIcon} className='account-icon' onClick={() => setOpenModal(true)} />
                        //     <Box>
                        //         <CenterBox>
                        //             <Typography className='address'>{formatAddress(address)}</Typography>
                        //             {/* <CopyAll className='copy-icon' onClick={copyAddress} /> */}
                        //         </CenterBox>
                        //         <Typography className='btn-disconnect' onClick={disconnect}>
                        //             Disconnect
                        //         </Typography>
                        //     </Box>
                        // </CenterBox>
                        <div className='fixed-balance flex flex-col bg-[#FAFCFE] border border-[#E8EFFB] px-2 py-1 rounded-lg absolute right-2 top-7'>
                            <div className='w-full flex items-center space-x-2 '>
                                <Image src={UserIcon} className='account-icon' onClick={() => setOpenModal(true)} />
                                <div className='w-full flex items-center justify-between'>
                                    <div className='w-full flex flex-col items-start'>
                                        {
                                            loadingBalance ? 'Loading...' :
                                                <div className='flex space-x-2 items-center'>
                                                    <p className='text-[18px] font-medium leading-[10px]'>{xrpBalance}</p>
                                                    <img src='https://static-00.iconduck.com/assets.00/xrp-cryptocurrency-icon-2048x2048-2a0bicgj.png' alt='xrp' width={18} height={18} />
                                                </div>
                                        }

                                        <Typography className='address'>{formatAddress(address)}</Typography>
                                    </div>
                                    <button onClick={() => setOpenBal(v => !v)}>
                                        {
                                            openBal ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />
                                        }
                                    </button>
                                </div>
                            </div>
                            {
                                openBal &&
                                <div className='flex flex-col justify-center items-center'>
                                    {
                                        balances.map(({ balance, tokenImage }, index) => (
                                            <div className='flex space-x-2 items-center mt-1'>
                                                <p className='text-[18px] font-medium leading-[10px]'>{Number(balance).toFixed(3)}</p>
                                                <img src={tokenImage} alt='xrp' width={18} height={18} />

                                            </div>
                                        ))
                                    }

                                    <button className='w-full text-white text-center bg-[#DF1616] border border-[#E8EFFB] rounded-lg' onClick={disconnect}>
                                        Disconnect
                                    </button>
                                </div>
                            }

                        </div>

                    }
                </CenterBox>
                <Menu
                    className="mobile-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {!address &&
                        <MenuItem className='connect-btn' onClick={handleWalletBtn}>
                            <img src={XummIcon} alt="" style={{ width: '95px', height: '14px' }} />
                        </MenuItem>
                    }
                    {!address &&
                        <MenuItem className='connect-btn' onClick={handleWalletCrossmark}>
                            Crossmark
                        </MenuItem>
                    }
                    <MenuItem onClick={handleClose}><Link href="/" >Home</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href="/swap" >Swap</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href="/liquidity/all" >Liquidity</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href="/projects" >Launchpad</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href="/bridge" >Bridge</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href="/faucet" >Faucet</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href="/leaderboard" >Leaderboard</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href="https://discord.gg/dmvTjyrjgh" >Discord</Link></MenuItem>
                    <MenuItem onClick={handleClose}><Link href={whitepaper_link} >Whitepaper</Link></MenuItem>
                </Menu>
            </Container>
        </Box>
    )
}
